const UsersSVG = ({classname}) => {
    return ( <svg className={classname} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className={classname} d="M15.6004 7.2001C15.6004 8.15488 15.2211 9.07055 14.546 9.74568C13.8708 10.4208 12.9552 10.8001 12.0004 10.8001C11.0456 10.8001 10.1299 10.4208 9.45481 9.74568C8.77968 9.07055 8.40039 8.15488 8.40039 7.2001C8.40039 6.24532 8.77968 5.32964 9.45481 4.65451C10.1299 3.97938 11.0456 3.6001 12.0004 3.6001C12.9552 3.6001 13.8708 3.97938 14.546 4.65451C15.2211 5.32964 15.6004 6.24532 15.6004 7.2001Z" fill="#7A7A7A"/>
    <path className={classname} d="M21.6003 9.6002C21.6003 10.2367 21.3474 10.8472 20.8973 11.2973C20.4473 11.7473 19.8368 12.0002 19.2003 12.0002C18.5638 12.0002 17.9533 11.7473 17.5032 11.2973C17.0531 10.8472 16.8003 10.2367 16.8003 9.6002C16.8003 8.96368 17.0531 8.35323 17.5032 7.90314C17.9533 7.45305 18.5638 7.2002 19.2003 7.2002C19.8368 7.2002 20.4473 7.45305 20.8973 7.90314C21.3474 8.35323 21.6003 8.96368 21.6003 9.6002Z" fill="#7A7A7A"/>
    <path className={classname} d="M7.20039 9.6002C7.20039 10.2367 6.94753 10.8472 6.49745 11.2973C6.04736 11.7473 5.43691 12.0002 4.80039 12.0002C4.16387 12.0002 3.55342 11.7473 3.10333 11.2973C2.65325 10.8472 2.40039 10.2367 2.40039 9.6002C2.40039 8.96368 2.65325 8.35323 3.10333 7.90314C3.55342 7.45305 4.16387 7.2002 4.80039 7.2002C5.43691 7.2002 6.04736 7.45305 6.49745 7.90314C6.94753 8.35323 7.20039 8.96368 7.20039 9.6002Z" fill="#7A7A7A"/>
    <path className={classname} d="M19.2003 21.6002V18.0002C19.202 16.7801 18.8922 15.5798 18.3003 14.513C18.8323 14.3768 19.3884 14.364 19.9261 14.4754C20.4639 14.5869 20.969 14.8197 21.4031 15.1561C21.8372 15.4924 22.1887 15.9235 22.4308 16.4164C22.673 16.9093 22.7993 17.451 22.8003 18.0002V21.6002H19.2003Z" fill="#7A7A7A"/>
    <path className={classname} d="M5.7002 14.513C5.10829 15.5799 4.79851 16.7801 4.8002 18.0002V21.6002H1.2002V18.0002C1.19996 17.4506 1.32555 16.9083 1.56733 16.4148C1.80911 15.9213 2.16067 15.4897 2.59506 15.1531C3.02944 14.8165 3.53513 14.5838 4.07337 14.4728C4.6116 14.3619 5.16809 14.3756 5.7002 14.513Z" fill="#7A7A7A"/>
    <rect className={classname} x="7.2002" y="13.2002" width="9.6" height="8.4" rx="3" fill="#7A7A7A"/>
    </svg>
     );
}
 
export default UsersSVG;