const LoginIcon = ({classname}) => {
    return ( 
        
        <svg width="650" height="650" viewBox="0 0 650 650" fill="none" xmlns="http://www.w3.org/2000/svg" className={classname||""}>
<path d="M650 497.12H0V497.445H650V497.12Z" fill="#EBEBEB"/>
<path d="M584.87 518.037H541.814V518.362H584.87V518.037Z" fill="#EBEBEB"/>
<path d="M430.586 521.573H419.289V521.898H430.586V521.573Z" fill="#EBEBEB"/>
<path d="M540.514 505.973H515.567V506.298H540.514V505.973Z" fill="#EBEBEB"/>
<path d="M124.345 508.157H68.198V508.482H124.345V508.157Z" fill="#EBEBEB"/>
<path d="M144.157 508.157H135.928V508.482H144.157V508.157Z" fill="#EBEBEB"/>
<path d="M292.695 513.643H170.911V513.968H292.695V513.643Z" fill="#EBEBEB"/>
<path d="M308.1 439.14H57.0831C55.1166 439.137 53.2319 438.353 51.8426 436.961C50.4534 435.57 49.6731 433.683 49.6731 431.717V78.858C49.6902 76.9028 50.478 75.0334 51.8655 73.6557C53.2529 72.278 55.1279 71.5033 57.0831 71.5H308.1C310.069 71.5 311.957 72.2821 313.349 73.6741C314.741 75.0662 315.523 76.9543 315.523 78.923V431.717C315.523 433.686 314.741 435.574 313.349 436.966C311.957 438.358 310.069 439.14 308.1 439.14ZM57.0831 71.76C55.2028 71.7634 53.4008 72.5128 52.0725 73.8435C50.7441 75.1743 49.9981 76.9777 49.9981 78.858V431.717C49.9981 433.597 50.7441 435.401 52.0725 436.731C53.4008 438.062 55.2028 438.812 57.0831 438.815H308.1C309.982 438.812 311.785 438.063 313.115 436.732C314.446 435.402 315.195 433.598 315.198 431.717V78.858C315.195 76.9765 314.446 75.1731 313.115 73.8428C311.785 72.5124 309.982 71.7634 308.1 71.76H57.0831Z" fill="#EBEBEB"/>
<path d="M589.303 439.14H338.273C336.305 439.137 334.419 438.353 333.028 436.962C331.637 435.571 330.854 433.685 330.85 431.717V78.858C330.871 76.9016 331.661 75.0322 333.051 73.6549C334.44 72.2775 336.317 71.5033 338.273 71.5H589.303C591.256 71.5068 593.128 72.2829 594.512 73.6602C595.897 75.0376 596.683 76.9051 596.7 78.858V431.717C596.7 433.681 595.922 435.565 594.535 436.957C593.149 438.348 591.267 439.133 589.303 439.14ZM338.273 71.76C336.392 71.7634 334.588 72.5124 333.258 73.8428C331.927 75.1731 331.179 76.9765 331.175 78.858V431.717C331.179 433.598 331.927 435.402 333.258 436.732C334.588 438.063 336.392 438.812 338.273 438.815H589.303C591.185 438.812 592.988 438.063 594.318 436.732C595.649 435.402 596.398 433.598 596.401 431.717V78.858C596.398 76.9765 595.649 75.1731 594.318 73.8428C592.988 72.5124 591.185 71.7634 589.303 71.76H338.273Z" fill="#EBEBEB"/>
<path d="M413.738 367.146H569.92C569.923 369.908 568.859 372.564 566.95 374.56C565.041 376.556 562.435 377.737 559.676 377.858C542.893 378.612 517.075 381.264 508.885 390.546C493.623 407.862 495.105 468.676 513.383 482.755C519.116 487.175 535.821 495.95 535.821 495.95H447.824C447.824 495.95 463.424 486.993 468.871 482.755C487.071 468.559 487.578 407.784 472.238 390.546C464.373 381.706 440.18 378.846 423.93 377.949C421.172 377.793 418.579 376.586 416.683 374.577C414.788 372.567 413.734 369.908 413.738 367.146Z" fill="#F5F5F5"/>
<path d="M550.576 378.417C534.703 379.717 515.697 382.824 508.885 390.546C506.66 393.213 504.902 396.237 503.685 399.49H477.49C476.266 396.24 474.509 393.217 472.29 390.546C465.452 382.863 446.29 379.704 430.69 378.417H550.576Z" fill="#E6E6E6"/>
<path d="M480.272 367.224L482.391 342.953H451.607L449.475 367.224H480.272Z" fill="#EBEBEB"/>
<path d="M480.272 367.224L482.391 342.953H484.042L481.923 367.224H480.272Z" fill="#E0E0E0"/>
<path d="M477.347 364.754L479.037 345.423H454.519L452.829 364.754H477.347Z" fill="#F0F0F0"/>
<path d="M475.462 363.168L476.879 347.022H456.404L454.987 363.168H475.462Z" fill="#FAFAFA"/>
<path d="M490.256 367.146H521.183L517.751 327.925H486.824L490.256 367.146Z" fill="#E6E6E6"/>
<path d="M490.256 367.146H488.774L485.342 327.925H486.824L490.256 367.146Z" fill="#E0E0E0"/>
<path d="M493.051 363.155H517.673L514.943 331.929H490.321L493.051 363.155Z" fill="#F0F0F0"/>
<path d="M494.858 360.581H515.424L513.136 334.503H492.57L494.858 360.581Z" fill="#FAFAFA"/>
<path d="M505.245 346.229C504.914 346.563 504.52 346.828 504.086 347.008C503.652 347.189 503.187 347.282 502.717 347.282C502.246 347.282 501.781 347.189 501.347 347.008C500.913 346.828 500.519 346.563 500.188 346.229C499.854 345.898 499.59 345.504 499.409 345.07C499.228 344.636 499.135 344.171 499.135 343.701C499.135 343.23 499.228 342.765 499.409 342.331C499.59 341.897 499.854 341.503 500.188 341.172C500.86 340.504 501.769 340.13 502.717 340.13C503.664 340.13 504.573 340.504 505.245 341.172C505.913 341.844 506.287 342.753 506.287 343.701C506.287 344.648 505.913 345.557 505.245 346.229Z" fill="#EBEBEB"/>
<path d="M503.737 350.623C503.979 350.377 504.267 350.182 504.585 350.049C504.903 349.916 505.245 349.847 505.589 349.847C505.934 349.847 506.276 349.916 506.594 350.049C506.912 350.182 507.2 350.377 507.442 350.623C507.819 350.986 508.077 351.454 508.184 351.966C508.29 352.478 508.24 353.011 508.039 353.494C507.838 353.977 507.496 354.388 507.058 354.674C506.62 354.959 506.106 355.106 505.583 355.095C505.061 355.103 504.549 354.955 504.113 354.668C503.676 354.381 503.337 353.97 503.138 353.487C502.938 353.005 502.889 352.474 502.996 351.963C503.103 351.452 503.361 350.985 503.737 350.623Z" fill="#F0F0F0"/>
<path d="M241.631 487.929H271.7L255.866 358.956C255.483 356.261 254.158 353.789 252.125 351.978C250.093 350.167 247.485 349.134 244.764 349.063H113.386L132.6 487.929H241.631Z" fill="#F5F5F5"/>
<path d="M146.094 487.929H115.973L100.1 358.956C99.9054 357.716 99.9855 356.449 100.335 355.244C100.684 354.038 101.294 352.924 102.121 351.981C102.948 351.037 103.972 350.287 105.121 349.782C106.271 349.278 107.516 349.033 108.771 349.063H119.171C121.892 349.134 124.5 350.167 126.532 351.978C128.565 353.789 129.89 356.261 130.273 358.956L146.094 487.929Z" fill="#F0F0F0"/>
<path d="M157.196 487.773L151.736 425.373C151.615 424.018 151.032 422.746 150.086 421.768C149.14 420.791 147.886 420.168 146.536 420.004L144.287 419.705C142.481 419.477 140.808 418.636 139.547 417.323C138.286 416.01 137.513 414.304 137.358 412.49L136.357 401.115C136.261 400.014 136.396 398.904 136.752 397.858C137.107 396.811 137.677 395.85 138.423 395.034C139.17 394.219 140.078 393.568 141.09 393.122C142.101 392.676 143.194 392.445 144.3 392.444H93.9899C92.8844 392.445 91.7912 392.676 90.7796 393.122C89.768 393.568 88.86 394.219 88.1133 395.034C87.3665 395.85 86.7973 396.811 86.4416 397.858C86.0859 398.904 85.9515 400.014 86.0469 401.115L87.0479 412.49C87.2031 414.304 87.9756 416.01 89.2366 417.323C90.4976 418.636 92.1708 419.477 93.9769 419.705L96.2259 420.004C97.5773 420.166 98.8318 420.788 99.7787 421.766C100.726 422.743 101.307 424.017 101.426 425.373L106.886 487.773C107.081 489.966 108.089 492.007 109.713 493.494C111.337 494.981 113.459 495.806 115.661 495.807H165.997C163.789 495.818 161.657 494.998 160.027 493.51C158.396 492.021 157.385 489.973 157.196 487.773Z" fill="#E6E6E6"/>
<path d="M295.724 409.773C296.14 405.051 298.307 400.655 301.8 397.451C305.294 394.246 309.86 392.465 314.6 392.457H270.79C266.047 392.462 261.478 394.241 257.982 397.446C254.486 400.651 252.317 405.049 251.901 409.773L247.572 459.173H291.395L295.724 409.773Z" fill="#E6E6E6"/>
<path d="M334.1 392.457H314.6C309.857 392.462 305.288 394.241 301.792 397.446C298.296 400.651 296.127 405.049 295.711 409.773L291.382 459.173H187.018L182.689 409.773C182.273 405.049 180.104 400.651 176.608 397.446C173.112 394.241 168.543 392.462 163.8 392.457H144.3C143.194 392.458 142.101 392.689 141.09 393.135C140.078 393.581 139.17 394.232 138.423 395.047C137.677 395.863 137.107 396.824 136.752 397.871C136.396 398.917 136.262 400.027 136.357 401.128L137.358 412.503C137.513 414.317 138.286 416.023 139.547 417.336C140.808 418.649 142.481 419.49 144.287 419.718L146.536 420.017C147.886 420.181 149.14 420.804 150.086 421.781C151.033 422.759 151.615 424.031 151.736 425.386L157.196 487.786C157.385 489.979 158.39 492.022 160.012 493.509C161.635 494.997 163.757 495.822 165.958 495.82H312.455C314.657 495.819 316.778 494.994 318.402 493.507C320.026 492.02 321.035 489.979 321.23 487.786L326.677 425.386C326.798 424.031 327.38 422.759 328.327 421.781C329.273 420.804 330.526 420.181 331.877 420.017L334.126 419.731C335.932 419.498 337.604 418.653 338.865 417.339C340.125 416.024 340.898 414.318 341.055 412.503L342.056 401.128C342.151 400.026 342.017 398.915 341.66 397.868C341.304 396.82 340.733 395.858 339.985 395.043C339.237 394.227 338.327 393.576 337.314 393.131C336.301 392.686 335.207 392.457 334.1 392.457Z" fill="#F0F0F0"/>
<path d="M510.458 170.677C510.458 170.677 511.992 160.641 504.66 154.193C497.328 147.745 497.978 143.923 500.071 143.429C502.164 142.935 504.101 149.37 507.312 151.138C507.312 151.138 507.416 141.96 504.153 138.58C500.89 135.2 499.161 126.49 502.268 126.334C505.375 126.178 505.713 131.534 506.35 135.278C506.987 139.022 510.458 144.378 510.458 144.378C510.458 144.378 511.134 136.162 513.253 132.782C515.372 129.402 509.73 121.667 511.953 118.287C514.176 114.907 517.998 121.355 517.673 128.596C517.348 135.837 512.473 143.572 513.643 151.138C513.643 151.138 515.775 145.821 517.699 145.34C519.623 144.859 521.534 141.336 522.821 140.036C524.108 138.736 525.72 143.247 522.821 147.433C519.922 151.619 512.525 162.812 513.643 171.873L510.458 170.677Z" fill="#E6E6E6"/>
<path d="M506.09 166.114L503.685 181.168C503.51 182.261 503.574 183.378 503.873 184.444C504.173 185.509 504.699 186.497 505.418 187.338C506.136 188.18 507.028 188.856 508.033 189.319C509.038 189.782 510.132 190.022 511.238 190.021H513.838C514.945 190.021 516.039 189.782 517.044 189.319C518.05 188.856 518.943 188.18 519.662 187.339C520.381 186.497 520.909 185.51 521.21 184.444C521.511 183.379 521.577 182.261 521.404 181.168L518.999 166.114H506.09Z" fill="#E6E6E6"/>
<path d="M542.373 184.47H372.801V193.18H542.373V184.47Z" fill="#E0E0E0"/>
<path d="M388.076 152.347H380.601V184.457H388.076V152.347Z" fill="#EBEBEB"/>
<path d="M424.203 152.347H416.728V184.457H424.203V152.347Z" fill="#EBEBEB"/>
<path d="M465.208 151.53L457.842 152.8L463.296 184.443L470.662 183.173L465.208 151.53Z" fill="#EBEBEB"/>
<path d="M397.332 156.585H389.857V184.47H397.332V156.585Z" fill="#EBEBEB"/>
<path d="M414.505 156.585H407.03V184.47H414.505V156.585Z" fill="#EBEBEB"/>
<path d="M457.847 156.585H450.372V184.47H457.847V156.585Z" fill="#EBEBEB"/>
<path d="M434.022 155.597L426.725 157.219L432.774 184.44L440.071 182.818L434.022 155.597Z" fill="#EBEBEB"/>
<path d="M403.78 150.761H400.049V184.47H403.78V150.761Z" fill="#EBEBEB"/>
<path d="M448.214 150.761H444.483V184.47H448.214V150.761Z" fill="#EBEBEB"/>
<path d="M372.788 258.219H542.36V249.509L372.788 249.509V258.219Z" fill="#E0E0E0"/>
<path d="M527.085 249.509H534.56V217.399H527.085V249.509Z" fill="#EBEBEB"/>
<path d="M490.958 249.509H498.433V217.399H490.958V249.509Z" fill="#EBEBEB"/>
<path d="M444.517 248.22L451.884 249.49L457.338 217.847L449.972 216.577L444.517 248.22Z" fill="#EBEBEB"/>
<path d="M517.842 249.509H525.317V221.624H517.842V249.509Z" fill="#EBEBEB"/>
<path d="M500.656 249.509H508.131V221.624H500.656V249.509Z" fill="#EBEBEB"/>
<path d="M457.314 249.509H464.789V221.624H457.314V249.509Z" fill="#EBEBEB"/>
<path d="M475.112 247.864L482.409 249.486L488.459 222.265L481.162 220.643L475.112 247.864Z" fill="#EBEBEB"/>
<path d="M511.368 249.509H515.099V215.8H511.368V249.509Z" fill="#EBEBEB"/>
<path d="M466.934 249.509H470.665V215.8H466.934V249.509Z" fill="#EBEBEB"/>
<path d="M389.077 249.21H425.477V224.081H389.077V249.21Z" fill="#EBEBEB"/>
<path d="M301.951 132.47H94.8351C93.313 132.47 92.0791 133.704 92.0791 135.226V138.177C92.0791 139.699 93.313 140.933 94.8351 140.933H301.951C303.473 140.933 304.707 139.699 304.707 138.177V135.226C304.707 133.704 303.473 132.47 301.951 132.47Z" fill="#E0E0E0"/>
<path d="M292.084 139.204H104.715V305.474H292.084V139.204Z" fill="#E6E6E6"/>
<path d="M287.157 143.754H109.408V300.989H287.157V143.754Z" fill="#F0F0F0"/>
<path d="M151.125 143.754L109.408 300.651V226.928L131.43 143.754H151.125Z" fill="white"/>
<path d="M165.893 143.754L123.812 301.002H117.104L159.185 143.754H165.893Z" fill="white"/>
<path d="M287.157 242.983V268.099L278.642 301.002H271.921L287.157 242.983Z" fill="white"/>
<path d="M171.392 140.933H168.402V304.512H171.392V140.933Z" fill="#E6E6E6"/>
<path d="M229.424 140.933H226.434V304.512H229.424V140.933Z" fill="#E6E6E6"/>
<path d="M261.547 135.2C261.547 135.2 265.863 191.451 290.654 220.116C290.654 220.116 272.597 292.513 272.61 317.759L306.163 311.558L294.073 220.558L299.858 135.2H261.547Z" fill="#E0E0E0"/>
<path d="M135.2 135.2C135.2 135.2 130.884 191.451 106.093 220.116C106.093 220.116 124.15 292.513 124.137 317.759L90.584 311.558L102.674 220.558L96.941 135.2H135.2Z" fill="#E0E0E0"/>
<path d="M325 555.828C464.207 555.828 577.057 549.239 577.057 541.112C577.057 532.985 464.207 526.396 325 526.396C185.793 526.396 72.9431 532.985 72.9431 541.112C72.9431 549.239 185.793 555.828 325 555.828Z" fill="#F5F5F5"/>
<path d="M500.591 503.789C500.591 503.373 499.187 462.28 492.531 455.819C486.122 449.579 478.725 455.585 478.413 455.819L477.581 454.818C477.672 454.753 486.187 447.798 493.441 454.818C500.474 461.656 501.839 501.969 501.891 503.672L500.591 503.789Z" fill="#7E57C2"/>
<path d="M501.176 511.875C500.981 509.86 496.587 462.475 514.488 441.909L515.463 442.754C497.939 462.865 502.463 511.264 502.463 511.745L501.176 511.875Z" fill="#7E57C2"/>
<path d="M500.76 506.701L499.46 506.61C499.46 506.064 502.957 451.087 489.905 439.088L490.789 438.126C504.296 450.554 500.916 504.4 500.76 506.701Z" fill="#7E57C2"/>
<path d="M502.463 506.662H501.163C501.163 505.141 501.605 469.417 511.797 463.58C512.774 462.948 513.889 462.56 515.047 462.449C516.206 462.338 517.374 462.507 518.453 462.943C524.628 465.543 527.683 476.45 527.813 476.918L526.513 477.256C526.513 477.152 523.51 466.453 517.92 464.139C517.021 463.781 516.049 463.647 515.087 463.746C514.125 463.846 513.201 464.177 512.395 464.711C502.892 470.171 502.476 506.298 502.463 506.662Z" fill="#7E57C2"/>
<path d="M498.524 506.688L497.224 506.623C497.224 506.337 498.745 478.491 491.257 468.702C489.45 466.362 487.513 465.257 485.472 465.387C480.597 465.725 476.502 472.875 476.463 472.953L475.319 472.316C475.501 471.991 479.817 464.516 485.368 464.087C487.864 463.918 490.204 465.192 492.284 467.909C500.071 478.088 498.589 505.531 498.524 506.688Z" fill="#7E57C2"/>
<path d="M499.928 510.484C499.72 507.78 495.001 444.054 503.321 432.783L504.374 433.563C496.327 444.431 501.163 509.73 501.215 510.393L499.928 510.484Z" fill="#7E57C2"/>
<path d="M520.663 469.495C520.663 469.495 515.684 471.328 517.166 475.657C518.648 479.986 534.391 487.123 534.391 487.123C534.391 487.123 540.982 481.078 534.924 471.263C528.866 461.448 520.793 466.401 520.663 469.495Z" fill="#7E57C2"/>
<path opacity="0.2" d="M520.663 469.495C520.663 469.495 515.684 471.328 517.166 475.657C518.648 479.986 534.391 487.123 534.391 487.123C534.391 487.123 540.982 481.078 534.924 471.263C528.866 461.448 520.793 466.401 520.663 469.495Z" fill="white"/>
<path d="M499.018 461.253C499.018 461.253 504.322 461.032 504.621 465.595C504.92 470.158 493.142 482.807 493.142 482.807C493.142 482.807 484.731 479.765 486.538 468.364C488.345 456.963 497.705 458.432 499.018 461.253Z" fill="#7E57C2"/>
<path opacity="0.2" d="M499.018 461.253C499.018 461.253 504.322 461.032 504.621 465.595C504.92 470.158 493.142 482.807 493.142 482.807C493.142 482.807 484.731 479.765 486.538 468.364C488.345 456.963 497.705 458.432 499.018 461.253Z" fill="white"/>
<path d="M494.533 439.4C494.533 439.4 498.069 439.023 497.783 436.878C497.497 434.733 484.042 429.182 476.112 438.178C476.112 438.178 481.195 446.914 488.54 447.018C495.885 447.122 494.533 439.4 494.533 439.4Z" fill="#7E57C2"/>
<path opacity="0.2" d="M494.533 439.4C494.533 439.4 498.069 439.023 497.783 436.878C497.497 434.733 484.042 429.182 476.112 438.178C476.112 438.178 481.195 446.914 488.54 447.018C495.885 447.122 494.533 439.4 494.533 439.4Z" fill="white"/>
<path d="M501.124 434.2C501.124 434.2 497.926 435.747 497.029 433.771C496.132 431.795 504.608 419.978 516.087 423.371C516.087 423.371 516.399 433.472 510.211 437.45C504.023 441.428 501.124 434.2 501.124 434.2Z" fill="#7E57C2"/>
<path opacity="0.2" d="M501.124 434.2C501.124 434.2 497.926 435.747 497.029 433.771C496.132 431.795 504.608 419.978 516.087 423.371C516.087 423.371 516.399 433.472 510.211 437.45C504.023 441.428 501.124 434.2 501.124 434.2Z" fill="white"/>
<path d="M484.393 467.753C484.393 467.753 486.824 465.79 484.198 464.828C481.572 463.866 468.598 464.139 468.507 476.931C468.507 476.931 483.548 479.427 485.407 474.552C487.266 469.677 484.393 467.753 484.393 467.753Z" fill="#7E57C2"/>
<path opacity="0.2" d="M484.393 467.753C484.393 467.753 486.824 465.79 484.198 464.828C481.572 463.866 468.598 464.139 468.507 476.931C468.507 476.931 483.548 479.427 485.407 474.552C487.266 469.677 484.393 467.753 484.393 467.753Z" fill="white"/>
<path d="M515.06 439.062C515.06 439.062 512.629 437.086 515.255 436.124C517.881 435.162 530.855 435.435 530.933 448.24C530.933 448.24 515.892 450.736 514.033 445.861C512.174 440.986 515.06 439.062 515.06 439.062Z" fill="#7E57C2"/>
<path opacity="0.2" d="M515.06 439.062C515.06 439.062 512.629 437.086 515.255 436.124C517.881 435.162 530.855 435.435 530.933 448.24C530.933 448.24 515.892 450.736 514.033 445.861C512.174 440.986 515.06 439.062 515.06 439.062Z" fill="white"/>
<path d="M507.104 449.371C507.104 449.371 506.896 446.966 508.833 447.915C510.77 448.864 517.491 456.118 510.51 463.008C510.51 463.008 501.124 456.053 502.814 452.426C504.504 448.799 507.104 449.371 507.104 449.371Z" fill="#7E57C2"/>
<path opacity="0.2" d="M507.104 449.371C507.104 449.371 506.896 446.966 508.833 447.915C510.77 448.864 517.491 456.118 510.51 463.008C510.51 463.008 501.124 456.053 502.814 452.426C504.504 448.799 507.104 449.371 507.104 449.371Z" fill="white"/>
<path d="M482.209 452.777C482.209 452.777 483.665 450.866 481.533 450.632C479.401 450.398 469.833 452.946 472.056 462.501C472.056 462.501 483.756 461.63 484.211 457.652C484.666 453.674 482.209 452.777 482.209 452.777Z" fill="#7E57C2"/>
<path opacity="0.2" d="M482.209 452.777C482.209 452.777 483.665 450.866 481.533 450.632C479.401 450.398 469.833 452.946 472.056 462.501C472.056 462.501 483.756 461.63 484.211 457.652C484.666 453.674 482.209 452.777 482.209 452.777Z" fill="white"/>
<path d="M521.599 542.126H481.585L477.282 498.329H525.915L521.599 542.126Z" fill="#263238"/>
<path d="M528.034 493.493H475.137V503.152H528.034V493.493Z" fill="#263238"/>
<path opacity="0.2" d="M528.034 493.493H475.137V503.152H528.034V493.493Z" fill="white"/>
<path d="M429.52 542.1H308.841C307.109 542.129 305.391 541.789 303.801 541.102C302.21 540.416 300.784 539.399 299.618 538.118C298.451 536.838 297.57 535.325 297.033 533.678C296.496 532.031 296.316 530.289 296.504 528.567L327.704 172.211C328.081 168.549 329.787 165.151 332.498 162.66C335.209 160.169 338.739 158.757 342.42 158.691H463.06C464.792 158.659 466.512 158.996 468.104 159.68C469.695 160.364 471.123 161.379 472.292 162.659C473.46 163.938 474.343 165.452 474.881 167.099C475.418 168.746 475.599 170.489 475.41 172.211L444.21 528.567C443.839 532.228 442.139 535.627 439.433 538.121C436.726 540.614 433.2 542.03 429.52 542.1Z" fill="#7E57C2"/>
<path opacity="0.4" d="M429.52 542.1H308.841C307.109 542.129 305.391 541.789 303.801 541.102C302.21 540.416 300.784 539.399 299.618 538.118C298.451 536.838 297.57 535.325 297.033 533.678C296.496 532.031 296.316 530.289 296.504 528.567L327.704 172.211C328.081 168.549 329.787 165.151 332.498 162.66C335.209 160.169 338.739 158.757 342.42 158.691H463.06C464.792 158.659 466.512 158.996 468.104 159.68C469.695 160.364 471.123 161.379 472.292 162.659C473.46 163.938 474.343 165.452 474.881 167.099C475.418 168.746 475.599 170.489 475.41 172.211L444.21 528.567C443.839 532.228 442.139 535.627 439.433 538.121C436.726 540.614 433.2 542.03 429.52 542.1Z" fill="white"/>
<path d="M416.52 542.1H295.841C294.109 542.129 292.391 541.789 290.801 541.102C289.21 540.416 287.784 539.399 286.618 538.118C285.451 536.838 284.57 535.325 284.033 533.678C283.496 532.031 283.316 530.289 283.504 528.567L314.704 172.211C315.081 168.549 316.787 165.151 319.498 162.66C322.209 160.169 325.739 158.757 329.42 158.691H450.06C451.792 158.659 453.512 158.996 455.104 159.68C456.695 160.364 458.123 161.379 459.292 162.659C460.46 163.938 461.343 165.452 461.881 167.099C462.418 168.746 462.599 170.489 462.41 172.211L431.21 528.567C430.839 532.228 429.139 535.627 426.433 538.121C423.726 540.614 420.2 542.03 416.52 542.1Z" fill="#263238"/>
<path d="M416.52 542.1H295.841C294.109 542.129 292.391 541.789 290.801 541.102C289.21 540.416 287.784 539.399 286.618 538.118C285.451 536.838 284.57 535.325 284.033 533.678C283.496 532.031 283.316 530.289 283.504 528.567L314.704 172.211C315.081 168.549 316.787 165.151 319.498 162.66C322.209 160.169 325.739 158.757 329.42 158.691H450.06C451.792 158.659 453.512 158.996 455.104 159.68C456.695 160.364 458.123 161.379 459.292 162.659C460.46 163.938 461.343 165.452 461.881 167.099C462.418 168.746 462.599 170.489 462.41 172.211L431.21 528.567C430.839 532.228 429.139 535.627 426.433 538.121C423.726 540.614 420.2 542.03 416.52 542.1Z" fill="#7E57C2"/>
<path d="M457.483 171.6L426.283 528.632C426.023 531.57 424.19 533.832 422.149 533.832H291.317C289.445 533.832 288.223 531.739 288.457 529.048L319.8 171.132C320.008 168.792 321.373 166.751 322.998 166.751H355.693C356.616 166.751 357.383 167.622 357.617 168.87L358.553 173.68C358.787 174.98 359.554 175.786 360.477 175.786H416.143C416.686 175.705 417.192 175.466 417.599 175.098C418.007 174.731 418.296 174.251 418.431 173.719L420.212 168.883C420.344 168.343 420.63 167.854 421.037 167.475C421.443 167.096 421.952 166.844 422.5 166.751H454.727C456.482 166.751 457.704 169 457.483 171.6Z" fill="#263238"/>
<path d="M455.949 189.059L429.143 495.404H291.395L318.201 189.059H455.949Z" fill="#7E57C2"/>
<path opacity="0.6" d="M334.074 181.142H335.777L336.063 177.866H334.36L334.074 181.142Z" fill="white"/>
<path opacity="0.6" d="M331.292 181.142H332.995L333.385 176.774H331.682L331.292 181.142Z" fill="white"/>
<path opacity="0.6" d="M328.523 181.142H330.226L330.707 175.682H329.004L328.523 181.142Z" fill="white"/>
<path opacity="0.6" d="M325.754 181.142H327.457L328.029 174.59H326.326L325.754 181.142Z" fill="white"/>
<path opacity="0.6" d="M441.155 179.946H440.141L440.44 176.67H441.441L441.155 179.946Z" fill="white"/>
<path opacity="0.6" d="M441.74 181.142H449.787L450.281 175.474H442.247L441.74 181.142Z" fill="white"/>
<path opacity="0.6" d="M388.96 167.219C388.83 168.727 387.881 169.949 386.828 169.949C385.775 169.949 385.047 168.727 385.177 167.219C385.307 165.711 386.269 164.489 387.309 164.489C388.349 164.489 389.09 165.711 388.96 167.219Z" fill="#263238"/>
<path opacity="0.6" d="M392.782 167.219C392.704 168.181 392.093 168.961 391.417 168.961C390.741 168.961 390.286 168.181 390.364 167.219C390.442 166.257 391.066 165.464 391.729 165.464C392.392 165.464 392.873 166.257 392.782 167.219Z" fill="#263238"/>
<path opacity="0.7" d="M455.949 189.059L452.231 231.556L451.594 238.888L448.617 272.935L448.149 278.226L443.924 326.495L443.469 331.786L439.413 378.131L438.828 384.8L429.156 495.404H291.395L318.201 189.059H455.949Z" fill="white"/>
<path d="M324.48 264.602L320.827 271.31L328.133 275.951L324.48 264.602Z" fill="#7E57C2"/>
<path d="M335.14 398.723L339.846 393.484L333.567 386.464L335.14 398.723Z" fill="#7E57C2"/>
<path d="M389.168 201.682L388.817 193.245L381.16 196.495L389.168 201.682Z" fill="#7E57C2"/>
<path d="M311.285 395.486L307.645 402.181L314.938 406.822L311.285 395.486Z" fill="#7E57C2"/>
<path d="M315.731 366.743L312.975 374.023L307.307 369.213L311.857 361.387L315.731 366.743Z" fill="#7E57C2"/>
<path d="M372.437 470.067L378.274 470.587L377.546 479.869L370.617 477.295L372.437 470.067Z" fill="#7E57C2"/>
<path d="M325 312.52L328.653 313.547C328.653 313.547 323.18 318.747 325.728 322.829C328.276 326.911 323.908 330.564 323.908 330.564L320.619 327.47C321.47 326.499 322.053 325.322 322.308 324.056C322.563 322.791 322.483 321.48 322.075 320.255C320.619 316.134 325 312.52 325 312.52Z" fill="#7E57C2"/>
<path d="M374.803 283.192L378.456 284.219C378.456 284.219 372.983 289.419 375.531 293.501C378.079 297.583 373.711 301.236 373.711 301.236L370.5 298.142C371.348 297.169 371.928 295.992 372.183 294.727C372.439 293.462 372.36 292.153 371.956 290.927C370.5 286.793 374.803 283.192 374.803 283.192Z" fill="#7E57C2"/>
<path d="M325.364 234.286L322.01 241.293L329.498 245.297L325.364 234.286Z" fill="#7E57C2"/>
<path d="M390.897 237.848L387.543 244.855L395.031 248.859L390.897 237.848Z" fill="#7E57C2"/>
<path d="M359.32 226.72L361.348 218.764L353.405 217.477L359.32 226.72Z" fill="#7E57C2"/>
<path d="M357.812 258.375L358.878 250.25L365.313 252.408L362.661 261.82L357.812 258.375Z" fill="#7E57C2"/>
<path d="M434.252 246.662L435.318 238.537L441.74 240.695L439.088 250.107L434.252 246.662Z" fill="#7E57C2"/>
<path d="M428.909 294.554L429.975 286.429L436.41 288.587L433.758 297.999L428.909 294.554Z" fill="#7E57C2"/>
<path d="M410.8 284.492L407.108 285.077C407.108 285.077 411.216 277.81 407.901 274.976C404.586 272.142 407.966 266.825 407.966 266.825L411.762 268.372C411.762 268.372 409.643 272.571 411.931 275.873C414.219 279.175 410.8 284.492 410.8 284.492Z" fill="#7E57C2"/>
<path d="M380.107 380.848L376.454 387.556L383.747 392.197L380.107 380.848Z" fill="#7E57C2"/>
<path d="M357.344 406.822L353.691 413.53L360.984 418.171L357.344 406.822Z" fill="#7E57C2"/>
<path d="M398.645 348.985L404.482 349.492L403.754 358.787L396.825 356.2L398.645 348.985Z" fill="#7E57C2"/>
<path d="M420.459 427.297L426.283 427.804L425.555 437.086L418.626 434.512L420.459 427.297Z" fill="#7E57C2"/>
<path d="M391.651 435.617L395.304 436.657C395.304 436.657 389.831 441.857 392.379 445.939C394.927 450.021 390.559 453.739 390.559 453.739L387.283 450.645C388.146 449.654 388.731 448.453 388.98 447.163C389.229 445.873 389.132 444.54 388.7 443.3C387.283 439.231 391.651 435.617 391.651 435.617Z" fill="#7E57C2"/>
<path d="M423.865 398.346L421.564 399.1C421.564 399.1 422.552 392.223 419.692 389.844C416.832 387.465 417.82 382.408 417.82 382.408L420.68 383.578C420.495 386.005 421.156 388.422 422.552 390.416C424.84 393.289 423.865 398.346 423.865 398.346Z" fill="#7E57C2"/>
<path d="M425.464 213.382L423.163 214.149C423.163 214.149 424.151 207.272 421.291 204.893C418.431 202.514 419.406 197.457 419.406 197.457L422.266 198.627C422.089 201.055 422.755 203.47 424.151 205.465C426.4 208.325 425.464 213.382 425.464 213.382Z" fill="#7E57C2"/>
<path d="M334.932 474.227L331.5 476.203C331.5 476.203 334.1 467.636 330.317 466.18C326.534 464.724 328.835 458.302 328.835 458.302H332.735C332.735 458.302 331.5 463.164 334.321 465.478C337.142 467.792 334.932 474.227 334.932 474.227Z" fill="#7E57C2"/>
<path d="M333.437 201.682C333.437 203.814 332.215 205.582 330.707 205.582C329.199 205.582 327.964 203.853 327.964 201.682C327.964 199.511 329.186 197.782 330.707 197.782C332.228 197.782 333.437 199.55 333.437 201.682Z" fill="#7E57C2"/>
<path d="M363.727 350.415C363.727 352.56 362.427 354.315 360.984 354.315C359.541 354.315 358.254 352.586 358.254 350.415C358.254 348.244 359.476 346.515 360.984 346.515C362.492 346.515 363.727 348.283 363.727 350.415Z" fill="#7E57C2"/>
<path d="M435.396 339.352C436.904 339.352 438.126 337.618 438.126 335.478C438.126 333.338 436.904 331.604 435.396 331.604C433.888 331.604 432.666 333.338 432.666 335.478C432.666 337.618 433.888 339.352 435.396 339.352Z" fill="#7E57C2"/>
<path d="M448.747 204.503C448.747 206.648 447.525 208.403 446.004 208.403C444.483 208.403 443.274 206.674 443.274 204.503C443.274 202.332 444.496 200.603 446.004 200.603C447.512 200.603 448.747 202.371 448.747 204.503Z" fill="#7E57C2"/>
<path d="M304.915 445.068L307.814 437.671L300.105 434.616L304.915 445.068Z" fill="#7E57C2"/>
<path d="M414.011 481.13L412.477 489.307L420.472 489.606L414.011 481.13Z" fill="#7E57C2"/>
<path d="M301.717 487.201C301.574 485.069 302.692 483.184 304.2 483.002C305.708 482.82 307.034 484.38 307.164 486.512C307.294 488.644 306.189 490.516 304.681 490.711C303.173 490.906 301.847 489.32 301.717 487.201Z" fill="#7E57C2"/>
<g opacity="0.5">
<path d="M324.48 264.602L320.827 271.31L328.133 275.951L324.48 264.602Z" fill="white"/>
<path d="M335.14 398.723L339.846 393.484L333.567 386.464L335.14 398.723Z" fill="white"/>
<path d="M389.168 201.682L388.817 193.245L381.16 196.495L389.168 201.682Z" fill="white"/>
<path d="M311.285 395.486L307.645 402.181L314.938 406.822L311.285 395.486Z" fill="white"/>
<path d="M315.731 366.743L312.975 374.023L307.307 369.213L311.857 361.387L315.731 366.743Z" fill="white"/>
<path d="M372.437 470.067L378.274 470.587L377.546 479.869L370.617 477.295L372.437 470.067Z" fill="white"/>
<path d="M325 312.52L328.653 313.547C328.653 313.547 323.18 318.747 325.728 322.829C328.276 326.911 323.908 330.564 323.908 330.564L320.619 327.47C321.47 326.499 322.053 325.322 322.308 324.056C322.563 322.791 322.483 321.48 322.075 320.255C320.619 316.134 325 312.52 325 312.52Z" fill="white"/>
<path d="M374.803 283.192L378.456 284.219C378.456 284.219 372.983 289.419 375.531 293.501C378.079 297.583 373.711 301.236 373.711 301.236L370.5 298.142C371.348 297.169 371.928 295.992 372.183 294.727C372.439 293.462 372.36 292.153 371.956 290.927C370.5 286.793 374.803 283.192 374.803 283.192Z" fill="white"/>
<path d="M325.364 234.286L322.01 241.293L329.498 245.297L325.364 234.286Z" fill="white"/>
<path d="M390.897 237.848L387.543 244.855L395.031 248.859L390.897 237.848Z" fill="white"/>
<path d="M359.32 226.72L361.348 218.764L353.405 217.477L359.32 226.72Z" fill="white"/>
<path d="M357.812 258.375L358.878 250.25L365.313 252.408L362.661 261.82L357.812 258.375Z" fill="white"/>
<path d="M434.252 246.662L435.318 238.537L441.74 240.695L439.088 250.107L434.252 246.662Z" fill="white"/>
<path d="M428.909 294.554L429.975 286.429L436.41 288.587L433.758 297.999L428.909 294.554Z" fill="white"/>
<path d="M410.8 284.492L407.108 285.077C407.108 285.077 411.216 277.81 407.901 274.976C404.586 272.142 407.966 266.825 407.966 266.825L411.762 268.372C411.762 268.372 409.643 272.571 411.931 275.873C414.219 279.175 410.8 284.492 410.8 284.492Z" fill="white"/>
<path d="M380.107 380.848L376.454 387.556L383.747 392.197L380.107 380.848Z" fill="white"/>
<path d="M357.344 406.822L353.691 413.53L360.984 418.171L357.344 406.822Z" fill="white"/>
<path d="M398.645 348.985L404.482 349.492L403.754 358.787L396.825 356.2L398.645 348.985Z" fill="white"/>
<path d="M420.459 427.297L426.283 427.804L425.555 437.086L418.626 434.512L420.459 427.297Z" fill="white"/>
<path d="M391.651 435.617L395.304 436.657C395.304 436.657 389.831 441.857 392.379 445.939C394.927 450.021 390.559 453.739 390.559 453.739L387.283 450.645C388.146 449.654 388.731 448.453 388.98 447.163C389.229 445.873 389.132 444.541 388.7 443.3C387.283 439.231 391.651 435.617 391.651 435.617Z" fill="white"/>
<path d="M423.865 398.346L421.564 399.1C421.564 399.1 422.552 392.223 419.692 389.844C416.832 387.465 417.82 382.408 417.82 382.408L420.68 383.578C420.495 386.005 421.156 388.422 422.552 390.416C424.84 393.289 423.865 398.346 423.865 398.346Z" fill="white"/>
<path d="M425.464 213.382L423.163 214.149C423.163 214.149 424.151 207.272 421.291 204.893C418.431 202.514 419.406 197.457 419.406 197.457L422.266 198.627C422.089 201.055 422.755 203.47 424.151 205.465C426.4 208.325 425.464 213.382 425.464 213.382Z" fill="white"/>
<path d="M334.932 474.227L331.5 476.203C331.5 476.203 334.1 467.636 330.317 466.18C326.534 464.724 328.835 458.302 328.835 458.302H332.735C332.735 458.302 331.5 463.164 334.321 465.478C337.142 467.792 334.932 474.227 334.932 474.227Z" fill="white"/>
<path d="M333.437 201.682C333.437 203.814 332.215 205.582 330.707 205.582C329.199 205.582 327.964 203.853 327.964 201.682C327.964 199.511 329.186 197.782 330.707 197.782C332.228 197.782 333.437 199.55 333.437 201.682Z" fill="white"/>
<path d="M363.727 350.415C363.727 352.56 362.427 354.315 360.984 354.315C359.541 354.315 358.254 352.586 358.254 350.415C358.254 348.244 359.476 346.515 360.984 346.515C362.492 346.515 363.727 348.283 363.727 350.415Z" fill="white"/>
<path d="M435.396 339.352C436.904 339.352 438.126 337.618 438.126 335.478C438.126 333.338 436.904 331.604 435.396 331.604C433.888 331.604 432.666 333.338 432.666 335.478C432.666 337.618 433.888 339.352 435.396 339.352Z" fill="white"/>
<path d="M448.747 204.503C448.747 206.648 447.525 208.403 446.004 208.403C444.483 208.403 443.274 206.674 443.274 204.503C443.274 202.332 444.496 200.603 446.004 200.603C447.512 200.603 448.747 202.371 448.747 204.503Z" fill="white"/>
<path d="M304.915 445.068L307.814 437.671L300.105 434.616L304.915 445.068Z" fill="white"/>
<path d="M414.011 481.13L412.477 489.307L420.472 489.606L414.011 481.13Z" fill="white"/>
<path d="M301.717 487.201C301.574 485.069 302.692 483.184 304.2 483.002C305.708 482.82 307.034 484.38 307.164 486.512C307.294 488.644 306.189 490.516 304.681 490.711C303.173 490.906 301.847 489.32 301.717 487.201Z" fill="white"/>
</g>
<path d="M389.441 444.34H336.141C334.351 444.398 332.569 444.071 330.915 443.384C329.261 442.696 327.774 441.662 326.552 440.352C325.331 439.042 324.404 437.486 323.833 435.788C323.263 434.09 323.062 432.29 323.245 430.508C323.68 426.718 325.49 423.218 328.331 420.672C331.172 418.126 334.848 416.709 338.663 416.689H391.963C393.752 416.632 395.533 416.957 397.185 417.645C398.838 418.332 400.325 419.364 401.546 420.673C402.767 421.981 403.695 423.536 404.266 425.232C404.837 426.928 405.04 428.727 404.859 430.508C404.426 434.301 402.618 437.803 399.777 440.352C396.935 442.901 393.258 444.32 389.441 444.34Z" fill="#7E57C2"/>
<path d="M332.28 229.164C332.308 228.107 332.736 227.1 333.476 226.345C334.216 225.591 335.215 225.144 336.271 225.095C336.755 225.076 337.236 225.179 337.67 225.395C338.104 225.611 338.477 225.932 338.754 226.33L338.052 227.045C337.848 226.749 337.574 226.508 337.254 226.344C336.934 226.181 336.578 226.1 336.219 226.109C335.431 226.151 334.688 226.489 334.138 227.056C333.589 227.622 333.273 228.375 333.255 229.164C333.176 229.527 333.179 229.903 333.262 230.265C333.346 230.627 333.508 230.966 333.738 231.258C333.968 231.55 334.259 231.787 334.591 231.953C334.924 232.119 335.289 232.21 335.66 232.219C336.044 232.216 336.423 232.129 336.769 231.963C337.115 231.796 337.42 231.555 337.662 231.257L338.234 231.985C337.905 232.388 337.49 232.714 337.021 232.939C336.551 233.163 336.038 233.282 335.517 233.285C333.45 233.233 332.059 231.517 332.28 229.164Z" fill="#7E57C2"/>
<path d="M339.196 229.164C339.227 228.106 339.658 227.099 340.4 226.344C341.142 225.59 342.142 225.144 343.2 225.095C343.697 225.104 344.186 225.222 344.633 225.442C345.079 225.661 345.471 225.976 345.782 226.365C346.092 226.754 346.313 227.206 346.429 227.69C346.544 228.174 346.551 228.677 346.45 229.164C346.421 230.221 345.994 231.228 345.254 231.983C344.513 232.737 343.515 233.184 342.459 233.233C341.961 233.224 341.472 233.105 341.025 232.886C340.578 232.667 340.185 232.352 339.874 231.963C339.562 231.575 339.34 231.123 339.223 230.639C339.106 230.155 339.097 229.652 339.196 229.164ZM345.475 229.164C345.555 228.804 345.555 228.43 345.475 228.07C345.395 227.71 345.236 227.372 345.01 227.08C344.784 226.789 344.496 226.55 344.168 226.382C343.839 226.214 343.478 226.121 343.109 226.109C342.324 226.155 341.586 226.495 341.041 227.061C340.497 227.627 340.186 228.378 340.171 229.164C340.09 229.525 340.09 229.9 340.171 230.261C340.253 230.622 340.413 230.961 340.64 231.252C340.867 231.544 341.156 231.783 341.487 231.95C341.817 232.117 342.18 232.209 342.55 232.219C343.332 232.17 344.067 231.829 344.609 231.263C345.151 230.697 345.46 229.947 345.475 229.164Z" fill="#7E57C2"/>
<path d="M354.198 225.186L353.47 233.142H352.664L349.102 227.214L348.569 233.142H347.594L348.322 225.186H349.115L352.677 231.114L353.223 225.186H354.198Z" fill="#7E57C2"/>
<path d="M360.62 229.112H361.556L361.283 232.219C360.541 232.868 359.59 233.228 358.605 233.233C358.105 233.228 357.613 233.113 357.164 232.895C356.714 232.677 356.318 232.362 356.005 231.973C355.692 231.584 355.469 231.13 355.352 230.644C355.235 230.159 355.227 229.653 355.329 229.164C355.361 228.102 355.795 227.091 356.543 226.336C357.29 225.581 358.297 225.137 359.359 225.095C359.849 225.073 360.337 225.174 360.778 225.39C361.218 225.606 361.598 225.929 361.881 226.33L361.205 227.032C360.986 226.737 360.699 226.499 360.369 226.339C360.038 226.178 359.674 226.099 359.307 226.109C358.512 226.142 357.759 226.476 357.202 227.043C356.644 227.61 356.323 228.369 356.304 229.164C356.239 229.528 356.252 229.901 356.342 230.259C356.433 230.617 356.599 230.952 356.829 231.241C357.059 231.53 357.348 231.767 357.676 231.935C358.005 232.104 358.366 232.201 358.735 232.219C359.325 232.229 359.903 232.052 360.386 231.712L360.62 229.112Z" fill="#7E57C2"/>
<path d="M367.627 233.142L366.327 230.698H365.898H364.208L363.987 233.116H363.012L363.74 225.16H366.405C366.773 225.112 367.147 225.152 367.496 225.277C367.846 225.402 368.16 225.608 368.414 225.878C368.668 226.149 368.854 226.476 368.957 226.832C369.06 227.189 369.076 227.565 369.005 227.929C368.989 228.471 368.816 228.997 368.507 229.442C368.198 229.888 367.765 230.234 367.263 230.438L368.641 233.116L367.627 233.142ZM368.082 227.942C368.186 226.811 367.588 226.161 366.34 226.161H364.676L364.351 229.736H366.015C366.27 229.767 366.528 229.744 366.774 229.669C367.02 229.595 367.247 229.469 367.442 229.302C367.636 229.134 367.794 228.928 367.904 228.696C368.014 228.464 368.075 228.212 368.082 227.955V227.942Z" fill="#7E57C2"/>
<path d="M374.699 231.153H371.072L370.136 233.142H369.2L373.022 225.186H373.984L376.363 233.142H375.336L374.699 231.153ZM374.439 230.243L373.321 226.343L371.488 230.243H374.439Z" fill="#7E57C2"/>
<path d="M378.963 226.2H376.623L376.714 225.212H382.369L382.278 226.2H379.938L379.301 233.168H378.3L378.963 226.2Z" fill="#7E57C2"/>
<path d="M382.837 229.71L383.253 225.186H384.228L383.812 229.658C383.656 231.426 384.28 232.258 385.502 232.258C386.724 232.258 387.517 231.465 387.673 229.658L388.089 225.186H389.025L388.622 229.71C388.401 232.011 387.205 233.233 385.411 233.233C383.617 233.233 382.629 232.011 382.837 229.71Z" fill="#7E57C2"/>
<path d="M391.04 225.186H392.015L391.378 232.154H395.07L394.992 233.142H390.312L391.04 225.186Z" fill="#7E57C2"/>
<path d="M400.673 231.153H397.046L396.11 233.142H395.109L398.931 225.186H399.893L402.272 233.142H401.245L400.673 231.153ZM400.413 230.243L399.295 226.343L397.475 230.243H400.413Z" fill="#7E57C2"/>
<path d="M404.937 226.2H402.597L402.688 225.212H408.343L408.2 226.2H405.86L405.223 233.168H404.3L404.937 226.2Z" fill="#7E57C2"/>
<path d="M409.409 225.186H410.384L409.656 233.142H408.681L409.409 225.186Z" fill="#7E57C2"/>
<path d="M411.515 229.164C411.553 228.106 411.987 227.1 412.731 226.347C413.474 225.593 414.474 225.146 415.532 225.095C416.029 225.104 416.518 225.222 416.965 225.442C417.411 225.661 417.804 225.976 418.114 226.365C418.424 226.754 418.645 227.206 418.761 227.69C418.876 228.174 418.883 228.677 418.782 229.164C418.753 230.221 418.326 231.228 417.586 231.983C416.846 232.737 415.847 233.184 414.791 233.233C414.292 233.226 413.801 233.109 413.353 232.89C412.904 232.671 412.51 232.357 412.197 231.968C411.884 231.58 411.661 231.127 411.543 230.642C411.425 230.157 411.415 229.653 411.515 229.164ZM417.807 229.164C417.885 228.804 417.883 228.431 417.802 228.072C417.721 227.713 417.562 227.376 417.336 227.085C417.111 226.794 416.824 226.555 416.497 226.387C416.169 226.218 415.809 226.123 415.441 226.109C414.655 226.154 413.915 226.494 413.369 227.06C412.822 227.626 412.508 228.377 412.49 229.164C412.411 229.526 412.414 229.901 412.497 230.262C412.579 230.623 412.741 230.961 412.969 231.253C413.197 231.544 413.487 231.782 413.818 231.949C414.148 232.116 414.512 232.208 414.882 232.219C415.664 232.17 416.399 231.829 416.941 231.263C417.483 230.697 417.792 229.947 417.807 229.164Z" fill="#7E57C2"/>
<path d="M426.517 225.186L425.802 233.142H424.996L421.434 227.214L420.901 233.142H419.9L420.628 225.186H421.434L424.996 231.114L425.529 225.186H426.517Z" fill="#7E57C2"/>
<path d="M427.31 232.232L427.7 231.4C427.979 231.694 428.316 231.926 428.69 232.083C429.064 232.24 429.466 232.317 429.871 232.31C431.054 232.31 431.613 231.777 431.665 231.088C431.847 229.151 427.596 230.373 427.869 227.422C427.951 226.75 428.291 226.137 428.818 225.712C429.345 225.287 430.016 225.085 430.69 225.147C431.461 225.126 432.215 225.374 432.822 225.849L432.419 226.759C431.903 226.344 431.261 226.115 430.599 226.109C429.442 226.109 428.896 226.681 428.831 227.409C428.649 229.359 432.9 228.137 432.64 231.062C432.555 231.739 432.208 232.355 431.673 232.778C431.138 233.202 430.458 233.397 429.78 233.324C429.316 233.319 428.857 233.22 428.433 233.032C428.008 232.844 427.626 232.572 427.31 232.232Z" fill="#7E57C2"/>
<path d="M340.925 430.742H342.524L342.199 434.317C341.298 435.028 340.187 435.421 339.04 435.435C336.648 435.435 335.075 433.537 335.309 430.885C335.348 429.683 335.845 428.541 336.698 427.693C337.551 426.845 338.696 426.354 339.898 426.322C340.491 426.29 341.083 426.414 341.614 426.681C342.145 426.947 342.598 427.348 342.927 427.843L341.627 429.052C341.427 428.742 341.151 428.489 340.826 428.316C340.501 428.143 340.136 428.056 339.768 428.064C339.033 428.084 338.336 428.39 337.823 428.917C337.311 429.444 337.025 430.15 337.025 430.885C336.945 431.219 336.941 431.567 337.014 431.902C337.086 432.238 337.234 432.553 337.445 432.824C337.656 433.095 337.925 433.315 338.233 433.468C338.54 433.62 338.878 433.702 339.222 433.706C339.718 433.712 340.205 433.577 340.626 433.316L340.925 430.742Z" fill="white"/>
<path d="M344.5 426.478H346.255L345.449 435.292H343.694L344.5 426.478Z" fill="white"/>
<path d="M349.7 428.116L349.492 430.443H353.002L352.846 432.081H349.336L349.05 435.292H347.295L348.101 426.478H353.821L353.678 428.116H349.7Z" fill="white"/>
<path d="M356.473 428.129H354.042L354.198 426.478H360.802L360.646 428.129H358.228L357.565 435.292H355.823L356.473 428.129Z" fill="white"/>
<path d="M363.48 434.421L364.221 432.887C364.893 433.468 365.751 433.79 366.639 433.797C367.666 433.797 368.134 433.394 368.186 432.861C368.329 431.21 363.818 432.341 364.117 429.065C364.162 428.658 364.287 428.265 364.485 427.906C364.683 427.548 364.95 427.233 365.27 426.978C365.59 426.723 365.957 426.534 366.35 426.421C366.744 426.309 367.155 426.275 367.562 426.322C368.491 426.284 369.407 426.559 370.162 427.102L369.486 428.649C368.894 428.213 368.18 427.972 367.445 427.96C366.405 427.96 365.963 428.415 365.911 428.961C365.768 430.586 370.266 429.468 369.98 432.705C369.932 433.112 369.803 433.506 369.603 433.864C369.402 434.221 369.133 434.536 368.811 434.79C368.488 435.043 368.119 435.231 367.724 435.341C367.329 435.452 366.916 435.484 366.509 435.435C365.409 435.478 364.332 435.117 363.48 434.421Z" fill="white"/>
<path d="M376.922 433.654L376.766 435.292H370.903L371.709 426.478H377.429L377.286 428.116H373.295L373.126 430.027H376.636L376.493 431.613H372.983L372.788 433.654H376.922Z" fill="white"/>
<path d="M385.918 426.478L385.112 435.292H383.669L380.38 429.936L379.886 435.292H378.157L378.963 426.478H380.419L383.695 431.821L384.189 426.478H385.918Z" fill="white"/>
<path d="M389.129 428.129H386.698L386.854 426.478H393.458L393.302 428.129H390.884L390.221 435.292H388.479L389.129 428.129Z" fill="white"/>
<path d="M418.858 335.877C425.539 303.138 410.963 272.518 386.302 267.485C361.64 262.452 336.232 284.912 329.55 317.651C322.869 350.39 337.445 381.01 362.106 386.043C386.768 391.076 412.176 368.616 418.858 335.877Z" fill="#7E57C2"/>
<path opacity="0.3" d="M418.858 335.877C425.539 303.138 410.963 272.518 386.302 267.485C361.64 262.452 336.232 284.912 329.55 317.651C322.869 350.39 337.445 381.01 362.106 386.043C386.768 391.076 412.176 368.616 418.858 335.877Z" fill="white"/>
<path d="M302.744 330.265L318.006 319.553L328.172 325.208L357.344 319.748L356.044 331.006L327.886 332.163C327.886 332.163 314.6 340.249 312 340.6C309.4 340.951 302.744 330.265 302.744 330.265Z" fill="#E4897B"/>
<path d="M419.627 325.936C419.562 326.69 419.484 327.457 419.406 328.211L345.683 333.84H345.163V331.5V322.049V317.954L346.723 317.642L418.132 302.9C419.819 310.455 420.324 318.226 419.627 325.936Z" fill="#7E57C2"/>
<path opacity="0.2" d="M419.627 325.936C419.562 326.69 419.484 327.457 419.406 328.211L345.683 333.84H345.163V331.5V322.049V317.954L346.723 317.642L418.132 302.9C419.819 310.455 420.324 318.226 419.627 325.936Z" fill="black"/>
<path d="M341.848 317.486L346.879 316.55L345.488 336.895L339.872 336.18L341.848 317.486Z" fill="#7E57C2"/>
<path opacity="0.5" d="M341.848 317.486L346.879 316.55L345.488 336.895L339.872 336.18L341.848 317.486Z" fill="white"/>
<path d="M266.994 297.882L267.202 297.856L267.189 297.83L267.215 297.817L267.202 297.765L266.994 297.882Z" fill="#263238"/>
<path d="M346.567 281.294L345.826 281.398L292.591 285.142L267.631 297.362L267.189 297.388L275.353 346.242L325.975 342.68L353.561 329.29L346.567 281.294Z" fill="#263238"/>
<path opacity="0.2" d="M273.624 300.82L275.821 302.588L278.525 301.769L277.524 304.408L279.123 306.722L276.315 306.592L274.612 308.828L273.871 306.111L271.206 305.175L273.559 303.641L273.624 300.82Z" fill="white"/>
<path opacity="0.2" d="M302.744 302.835V305.656L305.071 307.242L302.393 308.113L301.6 310.817L299.936 308.542L297.128 308.633L298.779 306.345L297.83 303.693L300.508 304.551L302.744 302.835Z" fill="white"/>
<path opacity="0.2" d="M303.927 333.164V335.985L306.254 337.571L303.589 338.455L302.796 341.159L301.132 338.884L298.311 338.962L299.962 336.674L299.013 334.022L301.691 334.893L303.927 333.164Z" fill="white"/>
<path opacity="0.2" d="M291.096 326.469L291.109 329.277L293.436 330.876L290.758 331.747L289.965 334.451L288.301 332.176L285.48 332.267L287.131 329.979L286.182 327.327L288.873 328.185L291.096 326.469Z" fill="white"/>
<path opacity="0.2" d="M305.019 324.545L305.032 326.196L306.384 327.132L304.824 327.639L304.356 329.225L303.381 327.899L301.73 327.938L302.705 326.612L302.146 325.052L303.719 325.559L305.019 324.545Z" fill="white"/>
<path opacity="0.2" d="M282.178 337.571V339.222L283.543 340.145L281.983 340.665L281.515 342.238L280.54 340.912L278.889 340.964L279.851 339.625L279.305 338.065L280.878 338.572L282.178 337.571Z" fill="white"/>
<path opacity="0.2" d="M279.149 321.62L279.162 323.271L280.514 324.194L278.954 324.714L278.486 326.3L277.511 324.961L275.873 325.013L276.835 323.674L276.276 322.127L277.849 322.634L279.149 321.62Z" fill="white"/>
<path opacity="0.2" d="M312.481 295.503V297.154L313.846 298.09L312.286 298.597L311.818 300.183L310.843 298.857L309.192 298.909L310.154 297.57L309.608 296.01L311.181 296.517L312.481 295.503Z" fill="white"/>
<path opacity="0.2" d="M283.517 308.789L285.402 312.013L289.12 312.286L286.637 315.068L287.521 318.682L284.115 317.187L280.943 319.15L281.32 315.445L278.473 313.027L282.113 312.234L283.517 308.789Z" fill="white"/>
<path opacity="0.2" d="M308.282 313.859L311.753 315.198L314.834 313.105L314.626 316.823L317.564 319.098L313.963 320.06L312.715 323.557L310.687 320.437L306.969 320.32L309.322 317.434L308.282 313.859Z" fill="white"/>
<path opacity="0.2" d="M316.329 329.29L318.214 332.514L321.932 332.787L319.449 335.569L320.333 339.183L316.927 337.688L313.755 339.638L314.132 335.933L311.285 333.528L314.925 332.735L316.329 329.29Z" fill="white"/>
<path opacity="0.2" d="M323.219 295.633L324.506 297.193L325.806 296.127L325.546 298.857L326.573 301.015L325.13 301.145L324.467 303.55L323.83 300.898L322.387 300.222L323.44 298.454L323.219 295.633Z" fill="white"/>
<path opacity="0.2" d="M338.286 294.931L338.559 297.752L339.898 299.13L338.611 300.261L338.455 303.03L337.402 300.911L335.959 301.262L336.596 298.818L335.855 296.257L337.311 296.868L338.286 294.931Z" fill="white"/>
<path opacity="0.2" d="M341.744 325.182L342.004 327.99L343.343 329.368L342.056 330.499L341.9 333.268L340.847 331.149L339.404 331.5L340.041 329.056L339.313 326.495L340.756 327.106L341.744 325.182Z" fill="white"/>
<path opacity="0.2" d="M334.555 319.67L334.815 322.478L336.154 323.856L334.867 324.987L334.711 327.769L333.658 325.637L332.228 325.988L332.852 323.544L332.111 320.983L333.567 321.594L334.555 319.67Z" fill="white"/>
<path opacity="0.2" d="M341.497 316.459L341.653 318.097L342.433 318.903L341.679 319.566L341.588 321.191L340.964 319.956L340.132 320.151L340.496 318.721L340.067 317.226L340.925 317.577L341.497 316.459Z" fill="white"/>
<path opacity="0.2" d="M331.032 331.604L331.188 333.255L331.968 334.061L331.214 334.711L331.123 336.349L330.499 335.101L329.667 335.309L330.031 333.879L329.602 332.371L330.447 332.735L331.032 331.604Z" fill="white"/>
<path opacity="0.2" d="M327.99 315.926L328.146 317.577L328.926 318.383L328.172 319.046L328.081 320.671L327.457 319.423L326.625 319.631L326.989 318.201L326.56 316.693L327.418 317.057L327.99 315.926Z" fill="white"/>
<path opacity="0.2" d="M342.589 286.702L342.745 288.353L343.525 289.159L342.771 289.809L342.693 291.447L342.069 290.199L341.224 290.394L341.588 288.977L341.159 287.469L342.017 287.833L342.589 286.702Z" fill="white"/>
<path opacity="0.2" d="M329.017 302.679L330.278 305.734L332.202 305.656L331.201 308.672L331.994 312.208L330.109 311.025L328.666 313.287L328.51 309.543L326.833 307.398L328.627 306.267L329.017 302.679Z" fill="white"/>
<path opacity="0.2" d="M342.16 305.448L344.058 306.475L345.436 304.083L345.683 307.827L347.399 309.842L345.644 311.116L345.332 314.743L344.006 311.805L342.095 312.039L343.031 308.932L342.16 305.448Z" fill="white"/>
<path opacity="0.2" d="M347.724 320.138L348.985 323.18L350.909 323.115L349.908 326.131L350.701 329.667L348.816 328.484L347.373 330.746L347.217 327.002L345.54 324.857L347.321 323.726L347.724 320.138Z" fill="white"/>
<path opacity="0.2" d="M317.785 293.969L346.58 281.515L353.587 329.68L326.014 343.239L317.785 293.969Z" fill="black"/>
<path opacity="0.2" d="M266.981 297.596L292.591 285.142L346.19 281.372L317.772 293.644L266.981 297.596Z" fill="white"/>
<path d="M344.071 334.49L337.168 284.856L284.908 288.535L280.15 291.46L327.977 289.393L337.493 337.428L344.071 334.49Z" fill="#7E57C2"/>
<path d="M318.604 283.309L294.801 295.204L301.21 344.422L293.644 344.955L284.96 296.062L311.662 283.803L318.604 283.309Z" fill="#7E57C2"/>
<path opacity="0.2" d="M337.194 285.285L327.99 289.497L337.506 337.597L344.084 334.698L337.194 285.285Z" fill="black"/>
<path opacity="0.1" d="M284.986 296.27L294.814 295.321L301.21 344.422L293.644 344.955L284.986 296.27Z" fill="black"/>
<path d="M303.212 288.288C303.212 288.288 288.249 278.564 282.295 281.684C277.576 284.141 278.733 290.446 291.174 291.187C286.572 291.928 281.151 293.046 277.966 294.528L287.339 294.983C292.83 293.058 298.469 291.585 304.2 290.576C304.507 291.613 304.582 292.705 304.421 293.774L310.739 292.773C310.739 292.773 308.737 291.122 307.112 289.913C314.353 288.678 329.81 285.233 330.902 278.213C332.254 269.1 310.18 276.705 303.212 288.288ZM282.412 283.491C283.712 281.021 302.367 289.133 302.367 289.133C294.034 290.901 281.086 286 282.425 283.491H282.412ZM304.85 288.561C304.85 288.561 323.128 273.416 327.99 276.159C332.852 278.902 310.7 288.704 304.863 288.6L304.85 288.561Z" fill="#7E57C2"/>
<path opacity="0.1" d="M303.212 288.288C303.212 288.288 288.249 278.564 282.295 281.684C277.576 284.141 278.733 290.446 291.174 291.187C286.572 291.928 281.151 293.046 277.966 294.528L287.339 294.983C292.83 293.058 298.469 291.585 304.2 290.576C304.507 291.613 304.582 292.705 304.421 293.774L310.739 292.773C310.739 292.773 308.737 291.122 307.112 289.913C314.353 288.678 329.81 285.233 330.902 278.213C332.254 269.1 310.18 276.705 303.212 288.288ZM282.412 283.491C283.712 281.021 302.367 289.133 302.367 289.133C294.034 290.901 281.086 286 282.425 283.491H282.412ZM304.85 288.561C304.85 288.561 323.128 273.416 327.99 276.159C332.852 278.902 310.7 288.704 304.863 288.6L304.85 288.561Z" fill="black"/>
<path d="M333.099 330.512L350.077 322.79L359.021 330.226H388.7L385.359 341.042L357.5 336.999C357.5 336.999 342.94 342.498 340.34 342.433C337.74 342.368 333.099 330.512 333.099 330.512Z" fill="#E4897B"/>
<path d="M419.549 326.729C419.549 327.236 419.458 327.717 419.406 328.211C418.557 335.736 416.644 343.103 413.725 350.09L374.322 343.2L373.893 343.122L374.595 339.443L376.389 330.187L376.909 327.444H378.404L419.549 326.729Z" fill="#7E57C2"/>
<path d="M373.893 325.143H379.002L373.893 344.89L368.511 343.161L373.893 325.143Z" fill="#7E57C2"/>
<path opacity="0.7" d="M373.893 325.143H379.002L373.893 344.89L368.511 343.161L373.893 325.143Z" fill="white"/>
<path d="M254.605 166.409L237.03 163.367C234.419 162.915 231.937 164.665 231.485 167.276L228.443 184.85C227.991 187.461 229.741 189.943 232.352 190.395L249.926 193.437C252.537 193.889 255.019 192.139 255.471 189.528L258.513 171.954C258.965 169.343 257.215 166.861 254.605 166.409Z" fill="#7E57C2"/>
<path opacity="0.6" d="M251.836 174.369C250.471 170.937 246.636 171.496 244.309 173.576C242.853 170.846 239.408 169.026 236.964 171.795C232.843 176.462 238.628 182.455 241.436 184.886C241.748 185.172 242.021 185.406 242.242 185.588L243.217 185.198C246.688 183.846 254.15 180.154 251.836 174.369Z" fill="white"/>
<path d="M244.4 190.32C244.4 190.32 239.447 197.821 231.062 198.978C231.062 198.978 235.898 197.21 237.016 188.695L244.4 190.32Z" fill="#7E57C2"/>
<path d="M250.367 242.151L255.177 230.048C255.177 230.048 254.748 225.381 255.177 224.744C255.606 224.107 267.371 216.177 267.371 216.177C267.371 216.177 269.191 220.519 269.074 222.456C268.957 224.393 261.04 229.164 261.04 229.164L256.243 241.033L250.367 242.151Z" fill="#B97964"/>
<path d="M259.727 235.482C259.727 235.482 243.958 271.388 237.445 274.482C232.986 276.601 217.711 274.482 208.533 272.844C204.308 272.116 201.383 271.544 201.383 271.544L206.973 264.095L211.276 258.362C211.276 258.362 225.446 264.225 230.932 265.252C236.418 266.279 252.707 231.751 252.707 231.751L259.727 235.482Z" fill="#7E57C2"/>
<path opacity="0.2" d="M208.533 272.831C204.308 272.103 201.383 271.531 201.383 271.531L206.973 264.082C207.809 265.36 208.378 266.794 208.646 268.297C208.914 269.801 208.876 271.343 208.533 272.831Z" fill="black"/>
<path d="M209.053 203.073C215.553 206.414 219.245 211.068 220.22 220.051C221.195 229.034 216.099 231.881 220.22 235.482C224.341 239.083 221.858 244.582 218.92 245.297C215.982 246.012 221 249.6 221 249.6L218.998 251.03C218.998 251.03 219.453 252.46 215.592 251.03C211.731 249.6 213.408 252.785 207.311 253.5C201.214 254.215 189.605 255.268 184.73 249.6C179.855 243.932 181.844 235.3 179.205 231.686C176.566 228.072 182.845 208.741 190.606 204.477C198.367 200.213 209.053 203.073 209.053 203.073Z" fill="#263238"/>
<path d="M137.059 204.516L141.284 192.179C141.284 192.179 144.651 188.929 144.716 188.162C144.781 187.395 141.05 173.706 141.05 173.706C141.05 173.706 136.838 175.838 135.655 177.372C134.472 178.906 137.397 187.694 137.397 187.694L133.315 199.836L137.059 204.516Z" fill="#B97964"/>
<path d="M170.287 527.956H161.694L164.32 516.074L166.764 505.167L177.528 503.516L173.693 516.464L170.287 527.956Z" fill="#B97964"/>
<path opacity="0.2" d="M173.602 516.724L164.242 516.308L166.725 505.284L177.502 503.581L173.602 516.724Z" fill="black"/>
<path d="M152.49 527.956H143.897L146.523 516.074L148.967 505.167L159.731 503.516L155.896 516.464L152.49 527.956Z" fill="#B97964"/>
<path opacity="0.2" d="M155.818 516.724L146.445 516.308L148.928 505.284L159.705 503.581L155.818 516.724Z" fill="black"/>
<path d="M136.929 300.833C136.929 300.833 136.5 301.756 135.811 303.433C132.574 311.428 123.708 336.674 127.023 365.95C128.973 383.266 148.109 414.44 150.956 431.418C154.271 451.178 145.6 486.018 147.823 511.589L161.499 512.785L165.62 510.185L177.32 508.443C177.32 508.443 207.831 439.075 207.22 415.09C206.778 396.253 177.32 328.718 177.32 328.718L180.128 303.745" fill="#263238"/>
<path opacity="0.2" d="M167.765 509.912L165.62 510.224L162.11 512.447C164.346 504.647 183.612 437.19 183.963 422.344C184.34 406.575 161.863 350.597 161.863 350.597L167.622 340.197L166.764 356.018C166.764 356.018 188.006 399.555 189.683 422.318C191.321 444.197 169.546 504.998 167.765 509.912Z" fill="black"/>
<path d="M159.263 527.592C159.263 526.643 170.963 527.384 170.963 527.384C170.963 527.384 178.126 534.664 185.081 536.302C192.036 537.94 188.162 542.126 180.375 542.126H161.135C155.584 542.1 159.042 537.407 159.263 527.592Z" fill="#263238"/>
<path d="M172.237 528.892L172.146 528.814C172.14 528.784 172.14 528.753 172.146 528.723C172.139 528.693 172.139 528.662 172.146 528.632C172.133 528.59 172.133 528.544 172.146 528.502C172.146 528.372 173.173 525.668 174.746 525.018C174.983 524.909 175.24 524.853 175.5 524.853C175.76 524.853 176.017 524.909 176.254 525.018C176.787 525.265 177.034 525.603 177.021 525.993C177.021 526.604 176.15 527.228 175.201 527.735C176.077 527.577 176.98 527.676 177.801 528.021C177.951 528.103 178.084 528.215 178.191 528.349C178.298 528.483 178.378 528.636 178.425 528.801C178.521 529.02 178.545 529.264 178.494 529.498C178.442 529.731 178.318 529.942 178.139 530.101C177.837 530.305 177.475 530.402 177.112 530.374C175.413 530.167 173.764 529.665 172.237 528.892ZM173.303 528.697C175.019 529.412 177.203 530.062 177.697 529.607C177.697 529.607 177.892 529.451 177.775 528.996C177.754 528.909 177.713 528.828 177.657 528.758C177.601 528.689 177.53 528.632 177.45 528.593C177.02 528.391 176.546 528.302 176.072 528.333C175.139 528.359 174.211 528.481 173.303 528.697ZM174.915 525.59C173.997 526.185 173.295 527.061 172.913 528.086C174.642 527.436 176.306 526.526 176.332 526.006C176.332 525.837 176.085 525.694 175.929 525.616C175.76 525.53 175.573 525.485 175.383 525.486C175.221 525.483 175.06 525.518 174.915 525.59Z" fill="#7E57C2"/>
<path d="M161.499 508.261L180.804 505.635L179.179 512.824L163.254 514.826L161.499 508.261Z" fill="#7E57C2"/>
<path d="M141.479 527.592C141.479 526.643 153.179 527.384 153.179 527.384C153.179 527.384 160.342 534.664 167.31 536.302C174.278 537.94 170.3 542.1 162.5 542.1H143.26C137.8 542.1 141.245 537.407 141.479 527.592Z" fill="#263238"/>
<path d="M154.44 528.892L154.349 528.814C154.342 528.784 154.342 528.753 154.349 528.723C154.349 528.723 154.349 528.723 154.349 528.632C154.343 528.589 154.343 528.545 154.349 528.502C154.349 528.372 155.376 525.668 156.949 525.018C157.185 524.909 157.443 524.852 157.703 524.852C157.963 524.852 158.221 524.909 158.457 525.018C158.99 525.265 159.25 525.603 159.224 525.993C159.224 526.604 158.353 527.228 157.404 527.735C158.28 527.577 159.183 527.676 160.004 528.021C160.158 528.098 160.294 528.208 160.401 528.343C160.509 528.477 160.586 528.634 160.628 528.801C160.728 529.019 160.754 529.264 160.703 529.499C160.651 529.733 160.524 529.945 160.342 530.101C160.04 530.305 159.678 530.402 159.315 530.374C157.616 530.167 155.967 529.665 154.44 528.892ZM155.506 528.697C157.222 529.412 159.406 530.062 159.9 529.607C159.9 529.607 160.095 529.451 159.978 528.996C159.957 528.909 159.916 528.828 159.86 528.758C159.804 528.689 159.733 528.632 159.653 528.593C159.223 528.391 158.749 528.302 158.275 528.333C157.342 528.359 156.414 528.481 155.506 528.697ZM157.118 525.59C156.204 526.186 155.506 527.062 155.129 528.086C156.845 527.436 158.509 526.526 158.548 526.006C158.548 525.837 158.288 525.694 158.132 525.616C157.963 525.53 157.776 525.485 157.586 525.486C157.424 525.483 157.263 525.518 157.118 525.59Z" fill="#7E57C2"/>
<path d="M145.002 508.261L165.62 505.635L163.891 512.824L146.887 514.826L145.002 508.261Z" fill="#7E57C2"/>
<path d="M139.425 295.217L137.41 301.015L178.087 311.766L180.466 304.902L139.425 295.217Z" fill="black"/>
<path d="M153.354 297.329L151.357 296.747L148.387 306.93L150.384 307.513L153.354 297.329Z" fill="#263238"/>
<path d="M170.605 303.048L161.677 300.81L159.98 307.582L168.908 309.819L170.605 303.048Z" fill="white"/>
<path d="M178.497 303.084L176.5 302.501L173.528 312.684L175.525 313.267L178.497 303.084Z" fill="#263238"/>
<path d="M211.276 258.323C209.508 260.624 207.87 262.782 206.362 264.823C200.434 272.753 196.209 278.382 192.985 282.919C189.35 287.802 186.197 293.026 183.573 298.519C182.52 300.742 181.48 303.173 180.323 306.046L139.425 295.139C142.815 282.265 146.876 269.577 151.593 257.127C152.724 254.215 153.751 251.706 154.661 249.548C155.571 247.39 156.364 245.648 157.027 244.088C157.027 244.01 157.027 243.932 157.131 243.841C158.136 241.597 159.908 239.786 162.129 238.733C164.35 237.679 166.874 237.453 169.247 238.095C172.185 238.888 175.63 239.889 178.815 240.968C184.744 243.165 190.512 245.771 196.079 248.768C201.367 251.585 206.446 254.778 211.276 258.323Z" fill="#7E57C2"/>
<path d="M184.873 256.867C177.996 249.067 178.581 243.672 178.581 243.672C187.603 243.36 190.593 231.712 190.593 231.712L198.393 241.683C197.002 242.786 195.698 243.994 194.493 245.297C189.423 250.9 191.893 253.214 191.893 253.214C190.957 257.725 184.873 256.867 184.873 256.867Z" fill="#B97964"/>
<path opacity="0.2" d="M198.406 241.683C197.015 242.786 195.711 243.994 194.506 245.297C190.918 240.279 190.528 231.712 190.528 231.712L198.406 241.683Z" fill="black"/>
<path d="M206.895 204.893C217.425 207.493 219.44 213.915 217.568 224.952C215.228 238.745 209.885 247.637 197.418 241.54C180.505 233.259 189.41 200.629 206.895 204.893Z" fill="#B97964"/>
<path d="M210.912 222.703C211.366 224.482 212.039 226.198 212.914 227.812C212.5 228.173 211.995 228.413 211.453 228.505C210.912 228.597 210.356 228.537 209.846 228.332L210.912 222.703Z" fill="#A24E3F"/>
<path d="M204.672 220.272C204.49 221.143 203.866 221.741 203.281 221.572C202.696 221.403 202.384 220.636 202.579 219.765C202.774 218.894 203.385 218.296 203.97 218.465C204.555 218.634 204.867 219.401 204.672 220.272Z" fill="#263238"/>
<path d="M203.723 218.465L205.777 218.127C205.777 218.127 204.477 219.518 203.723 218.465Z" fill="#263238"/>
<path d="M215.527 222.456C215.332 223.327 214.708 223.938 214.136 223.756C213.564 223.574 213.239 222.82 213.421 221.949C213.603 221.078 214.24 220.48 214.825 220.649C215.41 220.818 215.722 221.585 215.527 222.456Z" fill="#263238"/>
<path d="M214.565 220.649L216.619 220.324C216.619 220.324 215.332 221.702 214.565 220.649Z" fill="#263238"/>
<path d="M201.383 213.928C201.311 213.928 201.239 213.912 201.174 213.88C201.108 213.849 201.051 213.803 201.006 213.746C200.932 213.645 200.9 213.52 200.917 213.396C200.934 213.272 200.999 213.16 201.097 213.083C201.896 212.582 202.799 212.272 203.737 212.177C204.675 212.082 205.622 212.206 206.505 212.537C206.563 212.559 206.616 212.593 206.661 212.635C206.706 212.678 206.743 212.729 206.768 212.786C206.793 212.843 206.807 212.904 206.809 212.966C206.811 213.028 206.8 213.09 206.778 213.148C206.756 213.206 206.722 213.259 206.68 213.304C206.637 213.349 206.586 213.386 206.529 213.411C206.472 213.436 206.411 213.45 206.349 213.452C206.287 213.454 206.225 213.443 206.167 213.421C205.434 213.148 204.649 213.043 203.87 213.115C203.092 213.187 202.339 213.434 201.669 213.837C201.587 213.9 201.486 213.932 201.383 213.928Z" fill="#263238"/>
<path d="M218.088 216.762C218.022 216.762 217.956 216.749 217.896 216.722C217.835 216.695 217.781 216.655 217.737 216.606C217.31 216.062 216.766 215.623 216.144 215.321C215.522 215.019 214.84 214.863 214.149 214.864C214.087 214.871 214.025 214.865 213.966 214.848C213.906 214.831 213.851 214.802 213.803 214.764C213.754 214.725 213.714 214.677 213.684 214.623C213.655 214.569 213.636 214.509 213.629 214.448C213.622 214.387 213.627 214.324 213.645 214.265C213.662 214.206 213.691 214.15 213.729 214.102C213.768 214.054 213.816 214.013 213.87 213.984C213.924 213.954 213.983 213.935 214.045 213.928C214.882 213.911 215.713 214.086 216.472 214.44C217.232 214.794 217.9 215.317 218.426 215.969C218.471 216.012 218.507 216.063 218.532 216.12C218.556 216.177 218.569 216.238 218.569 216.3C218.569 216.363 218.556 216.424 218.532 216.481C218.507 216.538 218.471 216.589 218.426 216.632C218.334 216.718 218.213 216.764 218.088 216.762Z" fill="#263238"/>
<path d="M191.1 219.83C191.1 219.83 198.12 212.381 199.004 205.53C199.004 205.53 221.949 202.098 217.815 223.522C217.815 223.522 224.432 205.218 206.44 200.382C188.448 195.546 182.078 222.053 189.54 232.219C189.475 232.206 186.706 224.497 191.1 219.83Z" fill="#263238"/>
<path d="M192.244 222.469C192.443 221.232 192.145 219.966 191.414 218.948C190.683 217.93 189.58 217.242 188.344 217.035C184.99 216.606 181.844 222.924 189.527 226.512C190.606 227.019 191.542 226.044 192.244 222.469Z" fill="#B97964"/>
<path d="M224.133 228.241C221.39 225.641 221.819 221.221 223.951 216.749C226.083 212.277 220.558 208.949 220.558 208.949C220.558 208.949 220.103 203.307 215.774 201.032C214.981 200.616 211.38 201.032 211.38 201.032C206.648 195.832 199.043 191.568 193.063 198.341C189.553 202.332 186.056 198.237 180.479 206.57C176.449 212.576 179.257 217.88 178.087 219.817C172.679 228.709 179.712 231.114 181.389 234.208C181.389 234.208 185.107 232.427 184.795 231.374C182.273 222.846 186.693 217.321 190.112 218.686C193.531 220.051 195.988 219.674 198.471 216.541C200.954 213.408 199.225 210.041 204.971 209.261C210.717 208.481 211.289 205.192 211.289 205.192C211.387 206.656 211.878 208.067 212.711 209.275C213.544 210.483 214.688 211.444 216.021 212.056C220.805 214.565 218.92 217.594 218.257 221.871C217.594 226.148 219.7 227.799 223.457 230.893C227.214 233.987 223.236 236.249 223.236 236.249C223.951 235.889 224.564 235.357 225.022 234.7C225.479 234.043 225.765 233.283 225.854 232.488C225.943 231.693 225.832 230.888 225.532 230.146C225.231 229.405 224.75 228.75 224.133 228.241Z" fill="#263238"/>
<path d="M209.053 233.103C208.122 233.945 207.253 234.853 206.453 235.82L205.881 235.482C203.281 233.935 202.618 232.362 202.644 231.101C202.667 230.479 202.841 229.872 203.151 229.333C203.314 229.024 203.519 228.739 203.762 228.488C204.924 230.148 206.432 231.536 208.182 232.557C208.702 232.908 209.053 233.103 209.053 233.103Z" fill="#263238"/>
<path d="M208.182 232.557L207.389 233.337C204.971 231.881 203.489 230.529 203.151 229.333C203.313 229.024 203.519 228.739 203.762 228.488C204.924 230.148 206.432 231.536 208.182 232.557Z" fill="white"/>
<path d="M205.881 235.482C203.281 233.935 202.618 232.362 202.644 231.101C203.613 231.704 204.467 232.474 205.166 233.376C205.621 233.985 205.872 234.722 205.881 235.482Z" fill="#DE5753"/>
<path d="M182.338 237.809C182.338 237.809 189.696 247.208 198.406 247.39C198.406 247.39 194.077 254.41 195.442 258.011C195.442 258.011 189.956 263.666 182.442 258.7C174.928 253.734 173.693 240.5 173.693 240.5C173.693 240.5 179.088 240.24 182.338 237.809Z" fill="#7E57C2"/>
<path d="M166.79 237.809C166.79 237.809 150.657 233.909 139.347 232.154C128.037 230.399 141.791 194.87 141.791 194.87L134.927 191.529C134.927 191.529 119.158 225.628 126.295 236.275C130.156 242.047 154.7 249.6 154.7 249.6L166.79 237.809Z" fill="#7E57C2"/>
<path d="M218.985 248.586C218.985 248.586 219.856 246.363 222.391 248.586C224.926 250.809 225.615 247.286 227.162 247.598C228.709 247.91 228.865 245.193 232.648 244.322C236.431 243.451 236.925 243.438 237.614 241.722C238.303 240.006 241.046 240.006 241.046 240.006C241.046 240.006 238.446 237.614 240.357 235.469C242.268 233.324 240.188 228.54 240.188 228.54C240.188 228.54 243.789 227.487 245.154 230.061C246.519 232.635 246.363 233.324 246.363 233.324C246.363 233.324 246.87 229.164 245.674 228.319C245.674 228.319 251.16 231.777 248.924 235.807C247.789 238.076 245.825 239.822 243.438 240.682C243.438 240.682 242.242 244.894 240.357 245.284C238.686 245.786 237.158 246.677 235.898 247.884C234.533 248.911 234.702 248.859 232.128 249.314C229.554 249.769 228.696 253.487 227.162 253.513C225.968 253.344 224.755 253.344 223.561 253.513C222.014 253.89 221.429 256.659 218.985 255.281C216.541 253.903 215.267 254.514 214.097 253.513C212.927 252.512 207.48 253.89 207.298 250.458C207.116 247.026 215.943 244.816 218.985 248.586Z" fill="#263238"/>
<path opacity="0.2" d="M166.764 248.3C166.322 248.885 158.262 259.207 151.593 257.153C152.724 254.241 153.751 251.732 154.661 249.574C154.7 249.6 162.903 251.914 166.764 248.3Z" fill="black"/>
<path opacity="0.2" d="M176.215 240.201C176.215 240.201 178.906 251.654 186.03 254.631C193.154 257.608 194.493 254.098 194.493 254.098C194.493 254.098 193.791 264.147 186.03 260.39C182.276 258.722 179.233 255.781 177.437 252.087C175.641 248.393 175.208 244.183 176.215 240.201Z" fill="black"/>
<path opacity="0.7" d="M179.608 291.694L172.653 285.103L162.149 287.196L167.57 278.434L163.163 270.114L173.472 271.284L181.259 264.056L182.208 273.533L191.425 277.394L181.701 282.087L179.608 291.694Z" fill="white"/>
</svg>

     );
}
 
export default LoginIcon;