import React from "react";

function Plus({ classname }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classname}
    >
      <path
        d="M12 4.00001L12 12M12 12L12 20M12 12L20 12M12 12L4 12"
        stroke="white"
        strokeWidth="2"
        className={classname}
      />
    </svg>
  );
}

export default Plus;
