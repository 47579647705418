const OrderIcon = () => {
    return ( <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_14_521)">
    <rect x="0.666626" width="48" height="48" rx="24" fill="#EA5455"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.9666 12.6L33.9666 16.6C35.0666 17.1 35.6666 18.1 35.6666 19.2V28.7C35.6666 29.9 34.9666 30.9 33.9666 31.4L25.9666 35.4C25.5666 35.6 25.1666 35.7 24.6666 35.7C24.2666 35.7 23.7666 35.6 23.3666 35.4L15.3666 31.4C14.2666 30.9 13.6666 29.8 13.6666 28.7V19.2C13.6666 18.1 14.2666 17.1 15.2666 16.6L23.2666 12.6C24.1666 12.2 25.1666 12.2 25.9666 12.6ZM24.6666 14.2C24.5666 14.2 24.3666 14.2 24.2666 14.3L16.8666 18L24.6666 21.9L32.4666 18L25.0666 14.3C24.9666 14.2 24.8666 14.2 24.6666 14.2ZM16.1666 29.7C15.8666 29.5 15.6666 29.1 15.6666 28.8V19.6L23.6666 23.6V33.4L16.1666 29.7ZM25.6666 33.4L33.0666 29.7C33.4666 29.5 33.6666 29.2 33.6666 28.8V19.6L25.6666 23.6V33.4Z" fill="black"/>
    <mask id="mask0_14_521" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="13" y="12" width="23" height="24">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.9666 12.6L33.9666 16.6C35.0666 17.1 35.6666 18.1 35.6666 19.2V28.7C35.6666 29.9 34.9666 30.9 33.9666 31.4L25.9666 35.4C25.5666 35.6 25.1666 35.7 24.6666 35.7C24.2666 35.7 23.7666 35.6 23.3666 35.4L15.3666 31.4C14.2666 30.9 13.6666 29.8 13.6666 28.7V19.2C13.6666 18.1 14.2666 17.1 15.2666 16.6L23.2666 12.6C24.1666 12.2 25.1666 12.2 25.9666 12.6ZM24.6666 14.2C24.5666 14.2 24.3666 14.2 24.2666 14.3L16.8666 18L24.6666 21.9L32.4666 18L25.0666 14.3C24.9666 14.2 24.8666 14.2 24.6666 14.2ZM16.1666 29.7C15.8666 29.5 15.6666 29.1 15.6666 28.8V19.6L23.6666 23.6V33.4L16.1666 29.7ZM25.6666 33.4L33.0666 29.7C33.4666 29.5 33.6666 29.2 33.6666 28.8V19.6L25.6666 23.6V33.4Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_14_521)">
    <rect x="12.6666" y="12" width="24" height="24" fill="white"/>
    </g>
    </g>
    <defs>
    <clipPath id="clip0_14_521">
    <rect width="48" height="48" fill="white" transform="translate(0.666626)"/>
    </clipPath>
    </defs>
    </svg>
     );
}
 
export default OrderIcon;