const Customers = () =><svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_14_504)">
<rect x="0.833374" width="48" height="48" rx="24" fill="#00CFE8"/>
<path fillRule="evenodd" clipRule="evenodd" d="M19.8334 19C19.8334 16.2 22.0334 14 24.8334 14C27.6334 14 29.8334 16.2 29.8334 19C29.8334 21.8 27.6334 24 24.8334 24C22.0334 24 19.8334 21.8 19.8334 19ZM33.8334 31V33C33.8334 33.6 33.4334 34 32.8334 34C32.2334 34 31.8334 33.6 31.8334 33V31C31.8334 29.3 30.5334 28 28.8334 28H20.8334C19.1334 28 17.8334 29.3 17.8334 31V33C17.8334 33.6 17.4334 34 16.8334 34C16.2334 34 15.8334 33.6 15.8334 33V31C15.8334 28.2 18.0334 26 20.8334 26H28.8334C31.6334 26 33.8334 28.2 33.8334 31ZM24.8334 22C23.1334 22 21.8334 20.7 21.8334 19C21.8334 17.3 23.1334 16 24.8334 16C26.5334 16 27.8334 17.3 27.8334 19C27.8334 20.7 26.5334 22 24.8334 22Z" fill="black"/>
<mask id="mask0_14_504" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="15" y="14" width="19" height="20">
<path fillRule="evenodd" clipRule="evenodd" d="M19.8334 19C19.8334 16.2 22.0334 14 24.8334 14C27.6334 14 29.8334 16.2 29.8334 19C29.8334 21.8 27.6334 24 24.8334 24C22.0334 24 19.8334 21.8 19.8334 19ZM33.8334 31V33C33.8334 33.6 33.4334 34 32.8334 34C32.2334 34 31.8334 33.6 31.8334 33V31C31.8334 29.3 30.5334 28 28.8334 28H20.8334C19.1334 28 17.8334 29.3 17.8334 31V33C17.8334 33.6 17.4334 34 16.8334 34C16.2334 34 15.8334 33.6 15.8334 33V31C15.8334 28.2 18.0334 26 20.8334 26H28.8334C31.6334 26 33.8334 28.2 33.8334 31ZM24.8334 22C23.1334 22 21.8334 20.7 21.8334 19C21.8334 17.3 23.1334 16 24.8334 16C26.5334 16 27.8334 17.3 27.8334 19C27.8334 20.7 26.5334 22 24.8334 22Z" fill="white"/>
</mask>
<g mask="url(#mask0_14_504)">
<rect x="12.8334" y="12" width="24" height="24" fill="white"/>
</g>
</g>
<defs>
<clipPath id="clip0_14_504">
<rect width="48" height="48" fill="white" transform="translate(0.833374)"/>
</clipPath>
</defs>
</svg>
 
export default Customers;


