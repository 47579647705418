const OrderYuqorilashi = () =><svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_14_493)">
<rect x="0.5" width="48" height="48" rx="24" fill="#7367F0"/>
<path d="M36.5 18V24C36.5 24.6 36.1 25 35.5 25C34.9 25 34.5 24.6 34.5 24V20.4L26.7 28.2C26.3 28.6 25.7 28.6 25.3 28.2L21 23.9L14.2 30.7C14 30.9 13.8 31 13.5 31C13.2 31 13 30.9 12.8 30.7C12.4 30.3 12.4 29.7 12.8 29.3L20.3 21.8C20.7 21.4 21.3 21.4 21.7 21.8L26 26.1L33.1 19H29.5C28.9 19 28.5 18.6 28.5 18C28.5 17.4 28.9 17 29.5 17H35.5C35.6 17 35.8 17 35.9 17.1C36.1 17.2 36.3 17.4 36.4 17.6C36.5 17.7 36.5 17.9 36.5 18Z" fill="black"/>
<mask id="mask0_14_493" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="12" y="17" width="25" height="14">
<path d="M36.5 18V24C36.5 24.6 36.1 25 35.5 25C34.9 25 34.5 24.6 34.5 24V20.4L26.7 28.2C26.3 28.6 25.7 28.6 25.3 28.2L21 23.9L14.2 30.7C14 30.9 13.8 31 13.5 31C13.2 31 13 30.9 12.8 30.7C12.4 30.3 12.4 29.7 12.8 29.3L20.3 21.8C20.7 21.4 21.3 21.4 21.7 21.8L26 26.1L33.1 19H29.5C28.9 19 28.5 18.6 28.5 18C28.5 17.4 28.9 17 29.5 17H35.5C35.6 17 35.8 17 35.9 17.1C36.1 17.2 36.3 17.4 36.4 17.6C36.5 17.7 36.5 17.9 36.5 18Z" fill="white"/>
</mask>
<g mask="url(#mask0_14_493)">
<rect x="12.5" y="12" width="24" height="24" fill="white"/>
</g>
</g>
<defs>
<clipPath id="clip0_14_493">
<rect width="48" height="48" fill="white" transform="translate(0.5)"/>
</clipPath>
</defs>
</svg>
 
export default OrderYuqorilashi ;


