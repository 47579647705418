import React from "react";

function PaginationDots({ classname }) {
  return (
    <svg
      width="14"
      height="24"
      viewBox="0 0 14 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classname}
    >
      <path
        d="M2.256 14.802C2.68267 14.802 3.01867 14.9193 3.264 15.154C3.52 15.3887 3.648 15.7033 3.648 16.098C3.648 16.5033 3.52 16.8233 3.264 17.058C3.01867 17.282 2.68267 17.394 2.256 17.394C1.81867 17.394 1.47733 17.282 1.232 17.058C0.986667 16.8233 0.864 16.5033 0.864 16.098C0.864 15.7033 0.986667 15.3887 1.232 15.154C1.47733 14.9193 1.81867 14.802 2.256 14.802ZM6.756 14.802C7.18267 14.802 7.51867 14.9193 7.764 15.154C8.02 15.3887 8.148 15.7033 8.148 16.098C8.148 16.5033 8.02 16.8233 7.764 17.058C7.51867 17.282 7.18267 17.394 6.756 17.394C6.31867 17.394 5.97733 17.282 5.732 17.058C5.48667 16.8233 5.364 16.5033 5.364 16.098C5.364 15.7033 5.48667 15.3887 5.732 15.154C5.97733 14.9193 6.31867 14.802 6.756 14.802ZM11.256 14.802C11.6827 14.802 12.0187 14.9193 12.264 15.154C12.52 15.3887 12.648 15.7033 12.648 16.098C12.648 16.5033 12.52 16.8233 12.264 17.058C12.0187 17.282 11.6827 17.394 11.256 17.394C10.8187 17.394 10.4773 17.282 10.232 17.058C9.98667 16.8233 9.864 16.5033 9.864 16.098C9.864 15.7033 9.98667 15.3887 10.232 15.154C10.4773 14.9193 10.8187 14.802 11.256 14.802Z"
        fill="#AAAAAA"
        className={classname}
      />
    </svg>
  );
}

export default PaginationDots;
